import { css } from "@emotion/react";

import { headerResponsiveBreakPoint } from "../Header.style";

export const sidebar = css`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgb(238 238 238 / 97%); 
  transform: translateX(100%);
  display: none;
  transition: transform ease .3s;
  @media screen and (max-width: ${headerResponsiveBreakPoint}px) {
    display: block;
  }
`;

export const sidebarActive = css`
  transform: translateX(0);
`;

export const sidebarContent = css`
  width: 100%;
  height: 76%;
  position: relative;
  top: 14%;
  overflow-y: auto;
  overscroll-behavior: contain;
`;
