import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const nodeEnvironments = {
  development: "development",
  production: "production",
}

export const env = createEnv({
  client: {
    NEXT_PUBLIC_WEBSITE_URL: z.string().url(),
    NEXT_PUBLIC_RECAPTCHA_SITE_KEY: z.string(),
    NEXT_PUBLIC_SEND_MAIL_SECRET: z.string(),
  },
  runtimeEnv: {
    CAISY_API_KEY: process.env.CAISY_API_KEY,
    CAISY_PROJECT_ID: process.env.CAISY_PROJECT_ID,
    DRAFT_MODE_SECRET: process.env.DRAFT_MODE_SECRET,
    NEXT_PUBLIC_WEBSITE_URL: process.env.NEXT_PUBLIC_WEBSITE_URL,
    NODE_ENV: process.env.NODE_ENV,
    MAIL_SENDER_ADDRESS: process.env.MAIL_SENDER_ADDRESS,
    MAIL_RECEIVER_ADDRESS: process.env.MAIL_RECEIVER_ADDRESS,
    MAIL_PASSWORD: process.env.MAIL_PASSWORD,
    MAIL_USER: process.env.MAIL_USER,
    MAIL_HOST: process.env.MAIL_HOST,
    MAIL_PORT: process.env.MAIL_PORT,
    MAIL_SECURE: process.env.MAIL_SECURE,
    NEXT_PUBLIC_SEND_MAIL_SECRET: process.env.NEXT_PUBLIC_SEND_MAIL_SECRET,
    NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    RECAPTCHA_SECRET_KEY: process.env.RECAPTCHA_SECRET_KEY,
  },
  server: {
    CAISY_API_KEY: z.string(),
    CAISY_PROJECT_ID: z.string(),
    DRAFT_MODE_SECRET: z.string(),
    NODE_ENV: z.string().refine(s => Object.values(nodeEnvironments).includes(s)),
    MAIL_SENDER_ADDRESS: z.string().email(),
    MAIL_RECEIVER_ADDRESS: z.string().email(),
    MAIL_PASSWORD: z.string(),
    MAIL_USER: z.string(),
    MAIL_HOST: z.string(),
    MAIL_PORT: z.string().transform(s => parseInt(s, 10)).pipe(z.number()),
    MAIL_SECURE: z.string().refine(s => s === "true" || s === "false").transform(s => s === "true"),
    NEXT_PUBLIC_SEND_MAIL_SECRET: z.string(),
    RECAPTCHA_SECRET_KEY: z.string(),
  },
});
