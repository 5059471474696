/* eslint-disable max-lines */
import { css } from "@emotion/react";

import { colors } from "../../../styles/styleVariables";
import { breakpoints } from "../../../utils/breakPoints";

export const wrapper = css`
  background-color: ${colors.slate["900"]};
  color: ${colors.white};
  padding: 6rem 0;
  font-size: 1.5rem;
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    padding: 4rem 0;
  }
`;

export const contentWrapper = css`
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    max-width: 40rem;
  }
`;

export const linkAndNewsletterWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    flex-direction: column;
    align-items: unset;
  }
`;

export const logoAndLinksWrapper = css`
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    flex-direction: column;
  }
`;

export const logoWrapper = css`
  height: 3.6rem;
  width: auto;
  margin-bottom: 4rem;
  img {
    height: 100%;
    width: auto;
  }
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    
  }
`;

export const linksWrapper = css`
  display: flex;
  align-items: center;
  a {
    font-weight: 500;
    color: ${colors.white};
    margin-left: 3rem;
    :first-of-type {
      margin-left: 0;
    }
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;
    a {
      margin: 0;
    }
  }
`;

export const newsletterWrapper = css`
  max-width: 40rem;
  p {
    margin-bottom: 8px;
    font-weight: 500;
  }
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    max-width: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    p {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    display: none;
  }
`;

export const privacyBox = css`
  display: flex;
  align-items: center;
  margin: .6rem 0 1.2rem;
  span {
    padding: 0 1rem 0 0;
    margin-left: -.2rem;
  }
  p {
    word-break: break-word;
  }
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    margin-right: 3rem;
  }
`;

export const formWrapper = css`
  display: flex;
  align-items: stretch;
`;

export const emailInput = css`
  border: solid 1px rgba(255, 255, 255, 0.8);
  background-color: transparent;
  color: ${colors.white};
  padding: .6rem 1.8rem;
  margin-right: 1rem;
  border-radius: 6px;

  ::placeholder {
    color: ${colors.slate["400"]};
  }

  :focus, :active, :hover {
    outline: none;
    border: solid 1px ${colors.white};
  }
`;

export const copyrightWrapper = css`
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    p {
      text-align: center;
    }
  }
`;

export const copyRightAndSocial = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    max-width: 60%;
    margin-right: 2rem;
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    flex-direction: column;
    justify-content: unset;
    p {
      margin: 0 0 3rem;
      max-width: unset;
      text-align: center;
    }
  }
`;

export const socials = css`
  display: flex;
  align-items: center;
`;

export const social = css`
  margin-left: 2rem;
  img {
    height: 2.3rem;
    width: auto;
  }
`;

export const separationLine = css`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const separationLine1 = css`
  margin: 3rem 0;
  display: none;
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    display: block;
  }
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    display: none;
  }
`;

export const separationLine2 = css`
  margin: 6rem 0 4rem;
  @media screen and (max-width: ${breakpoints.tablet1024}px) {
    margin: 3rem 0;
  }
`;

export const separationLine3 = css`
  display: none;
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    margin: 3rem 0;
    display: block;
  }
`;
