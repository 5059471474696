import { type IGenNavLink_LinkZiel } from "@/services/graphql/__generated/sdk";
import { type Nullable } from "@/utils/types";

export const getPageSlug = (linkZiel: Nullable<IGenNavLink_LinkZiel>): string =>
{
  if(!linkZiel)
  {
    return "/";
  }

  switch (linkZiel.__typename)
  {
    case "Page":
    {
      return linkZiel.slug ? `/${linkZiel.slug}` : "/";
    }
    case "NewsOverviewPage":
    {
      return "/news";
    }
    case "BlogOverviewPage":
    {
      return "/blog";
    }
    default:
    {
      console.error("Unknown linkZiel.__typename", linkZiel.__typename);
      return "/";
    }
  }
};
