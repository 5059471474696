import AlertDialog from "@/components/common/alertDialog/AlertDialog";
import { type IGenFooter } from "@/services/graphql/__generated/sdk";
import { getPageSlug } from "@/utils/getPageSlug";

import { Button, Checkbox } from "@mui/material";
import axios from "axios";
import Link from "next/link";
import React, { type FunctionComponent, useState } from "react";

import * as styles from "./Footer.style";
import ContentWrapper from "../contentWrapper/ContentWrapper";

const Footer: FunctionComponent<IGenFooter> = ({
  copyrightText,
  links,
  logo,
  newsletterCta,
  newsletterCtaDataPrivacyStatement,
  newsletterDIalogConfirmButtonText,
  newsletterDialogDescription,
  newsletterDialogTitle,
  newsletterText,
  socialLinks
}) =>
{
  const currentYear = new Date().getFullYear();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState("");
  const [hasAcceptedPrivacy, setHasAcceptedPrivacy] = useState(false);

  return (
    <div css={styles.wrapper}>
      <ContentWrapper css={styles.contentWrapper}>
        <div css={styles.linkAndNewsletterWrapper}>
          <div css={styles.logoAndLinksWrapper}>
            <div css={styles.logoWrapper}>
              <img
                loading="lazy"
                src={logo?.src || ""}
                alt={logo?.title || ""}
              />
            </div>
            <div css={[styles.separationLine, styles.separationLine3]}/>
            <div css={styles.linksWrapper}>
              {links?.filter(Boolean).map(({ id, linkText, linkZiel }) => (
                <Link key={id} href={getPageSlug(linkZiel)}>
                  {linkText}
                </Link>
              ))}
            </div>
          </div>
          <div css={[styles.separationLine, styles.separationLine1]}/>
          <div css={styles.newsletterWrapper}>
            <div>
              <p>{newsletterText}</p>
              <div css={styles.privacyBox}>
                <Checkbox checked={hasAcceptedPrivacy} onClick={() => setHasAcceptedPrivacy(!hasAcceptedPrivacy)}/>
                <p style={{ fontSize: 12, margin: 0 }}>{newsletterCtaDataPrivacyStatement}</p>
              </div>
            </div>
            <form
              css={styles.formWrapper}
              onSubmit={async e => 
              {
                e.preventDefault();

                try
                {
                  const response = await axios.get(`https://hooks.zapier.com/hooks/catch/1150154/3tu2pls?formTitle=Newsletter&email=${encodeURIComponent(newsletterEmail)}`);

                  if(response.data.status === "success")
                  {
                    setIsAlertOpen(true);
                    setHasAcceptedPrivacy(false);
                    setNewsletterEmail("");
                  }
                  else
                  {
                    setIsErrorAlertOpen(true);
                  }
                }
                catch (error) 
                {
                  setIsErrorAlertOpen(true);
                }
              }}>
              <input
                css={styles.emailInput}
                placeholder="E-Mail-Adresse"
                value={newsletterEmail}
                onChange={(e) => setNewsletterEmail(e.target.value)}
                type="email"
                required
              />
              <Button
                type="submit"
                disabled={!hasAcceptedPrivacy}
                variant="contained">
                {newsletterCta}
              </Button>
            </form>
          </div>
        </div>
        <div css={[styles.separationLine, styles.separationLine2]}/>
        <div css={styles.copyRightAndSocial}>
          <p>© {currentYear} {copyrightText}</p>
          <div css={styles.socials}>
            {socialLinks?.filter(Boolean).map(({ icon, id, url }) => (
              <Link
                key={id}
                css={styles.social}
                href={url || ""}
                target="_blank">
                <img
                  loading="lazy"
                  src={icon?.src || ""}
                  alt={icon?.title || ""}
                />
              </Link>
            ))}
          </div>
        </div>
        <AlertDialog
          confirmButtonText={newsletterDIalogConfirmButtonText || ""}
          description={newsletterDialogDescription || ""}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          title={newsletterDialogTitle || ""}
        />
        <AlertDialog
          confirmButtonText="Ok"
          description="Something went wrong. Please try again later."
          isAlertOpen={isErrorAlertOpen}
          setIsAlertOpen={setIsErrorAlertOpen}
          title="Something went wrong"
        />
      </ContentWrapper>
    </div>
  );
};

export default Footer;
