import React, { type ComponentProps, type FunctionComponent } from "react"; 

import * as styles from "./ContentWrapper.style";
import * as globalStyles from "../../../styles/globals.style";
import { contentAreaWidth } from "../../../styles/styleVariables";

interface IProps extends ComponentProps<"div"> 
{
  readonly maxWidthInPx?: number;
}

const ContentWrapper: FunctionComponent<IProps> = ({
  maxWidthInPx,
  ...props
}) => (
  <div
    {...props}
    css={[
      globalStyles.centerHorizontally,
      styles.wrapper(maxWidthInPx ?? contentAreaWidth)
    ]}>
    {props.children}
  </div>
);

export default ContentWrapper;
