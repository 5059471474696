import { colors } from "@/styles/styleVariables";

import { css, type SerializedStyles } from "@emotion/react";

export const wrapper = (responsiveBreakpointInPx: number, color: string): SerializedStyles => css`
  height: 3.4rem;
  width: 3.4rem;
  position: absolute;
  z-index: 100;
  right: 5%;
  top: 50%;
  transform: translateY(-50%) scale(.9);
  display: none;
  cursor: pointer;
  
  div, div:before, div:after {
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: ${color};
    border-radius: 3px;
  }
  
  div {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: background-color ease 0.3s;
    &:before, &:after {
      content: '';
      transition: transform ease .5s, background-color ease .3s, top ease .5s;
    }
  
    &:before {
      top: -1.1rem
    }
    &:after {
      top: 1.1rem
    }
  }

  @media (max-width: ${responsiveBreakpointInPx}px) 
  {
    display: block;
  }
`;

export const isActive = css`
  div {
    background-color: rgba(15, 23, 42, 0);
    transition: 0ms;

    &:before {
      top: 0;
      transform: rotate(45deg);
      background-color: ${colors.slate["900"]}
    }

    &:after {
      top: 0;
      transform: rotate(135deg);
      background-color: ${colors.slate["900"]}
    }
  }
`;

