import { type IGenNavLink } from "@/services/graphql/__generated/sdk";
import { getPageSlug } from "@/utils/getPageSlug";

import Link from "next/link";
import React, { type ComponentProps, type FunctionComponent } from "react";

import * as styles from "./Navigation.style";

interface IProps extends ComponentProps<"nav"> 
{
  readonly closeSidebar?: () => void;
  readonly isScrolled: boolean;
  readonly isSidebar: boolean;
  readonly links: (IGenNavLink | undefined | null)[] | undefined | null;
}

const Navigation: FunctionComponent<IProps> = ({
  closeSidebar,
  isScrolled,
  isSidebar,
  links,
  ...props
}) =>
{
  const onClick = (): void =>
  {
    if(!isSidebar || !closeSidebar)
    {
      return;
    }

    closeSidebar();
  };

  return (
    <nav {...props} css={[!isSidebar && styles.navDesktop]}>
      <div css={[isSidebar ? styles.linksWrapperSidebar : styles.linksWrapperDesktop(isScrolled)]}>
        {links?.map((link, index) => (
          <Link key={index} href={getPageSlug(link?.linkZiel)} onClick={onClick}>
            {link?.linkText}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Navigation;
