import { env } from "@/env.mjs";
import {
  type IGenFooter, type IGenPageSeo, type IGenSeo
} from "@/services/graphql/__generated/sdk";
import { type Nullable } from "@/utils/types";

import React, { type FunctionComponent, type ReactElement, type ReactNode } from "react";

import Footer from "../footer/Footer";
import Header, { type HeaderProps } from "../header/Header";
import PageHead from "../pageHead/PageHead";

interface IProps
{
  readonly additionalHtmlHeadTags?: ReactElement;
  readonly children: ReactNode;
  readonly footerProps: IGenFooter;
  readonly headerProps: HeaderProps;
  readonly pageSeo: Nullable<IGenPageSeo>;
  readonly pageTitle: string;
  readonly websiteSeo: IGenSeo;
}

const PageWrapper: FunctionComponent<IProps> = ({
  additionalHtmlHeadTags,
  children,
  footerProps,
  headerProps,
  pageSeo,
  pageTitle,
  websiteSeo,
}) => (
  <>
    <PageHead
      additionalHtmlHeadTags={additionalHtmlHeadTags}
      pageTitle={pageSeo?.pageTitle || pageTitle || "undefined"}
      pageDescription={pageSeo?.pageDescription}
      pageOgImage={pageSeo?.openGraphImage?.src}
      websiteOgImage={websiteSeo.openGraphImage?.src}
      websiteTitle={websiteSeo.websiteTitle || ""}
      faviconPng={websiteSeo.faviconPng?.src}
      faviconIco={websiteSeo.faviconIco?.src}
      themeColor={websiteSeo.themeColor}
      websiteUrl={env.NEXT_PUBLIC_WEBSITE_URL}
    />
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-PRPZ7WH"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
    <Header {...headerProps}/>
    {children}
    <Footer {...footerProps}/>
  </>
);

export default PageWrapper;
