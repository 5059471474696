import React, { type ComponentProps, type FunctionComponent } from "react";

import * as styles from "./Sidebar.style";

interface IProps extends ComponentProps<"div"> 
{
  readonly isActive: boolean;
  readonly navigationItems: React.ReactElement;
}

const Sidebar: FunctionComponent<IProps> = ({
  isActive,
  navigationItems,
  ...props
}) => (
  <div {...props} css={[styles.sidebar, isActive && styles.sidebarActive]}>
    <div css={styles.sidebarContent}>
      {navigationItems}
    </div>
  </div>
);

export default Sidebar;
