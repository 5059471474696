import React, { type FunctionComponent, type ComponentProps } from "react";

import * as styles from "./BurgerMenu.style";

interface IProps extends ComponentProps<"div">
{
  readonly color: string;
  readonly isActive: boolean;
  readonly onClick: () => any;
  readonly responsiveBreakpointInPx: number;
}

const BurgerMenu: FunctionComponent<IProps> = ({
  color,
  isActive,
  responsiveBreakpointInPx,
  ...props
}) => (
  <div
    {...props}
    css={[
      styles.wrapper(responsiveBreakpointInPx, color),
      isActive && styles.isActive
    ]}>
    <div/>
  </div>
);

export default BurgerMenu;
