import { css } from "@emotion/react";

import { colors } from "../../../../styles/styleVariables";
import { headerAnimationDuration, headerResponsiveBreakPoint } from "../Header.style";

export const navDesktop = css`
  @media screen and (max-width: ${headerResponsiveBreakPoint}px) {
    display: none;
  }
`;

export const linksWrapperDesktop = (isScrolled: boolean) => css`
  display: flex;
  a {
    text-transform: uppercase;
    line-height: 1;
    font-weight: 400;
    margin-right: 3rem;
    transition: color ease ${headerAnimationDuration}s, opacity ease 150ms;
    color: ${isScrolled ? colors.slate["900"] : colors.white};
    position: relative;
    :hover, :focus, :active {
      opacity: .66;
    }
  }
`;

export const linksWrapperSidebar = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  a {
    font-weight: 500;
    font-size: 3rem;
    color: ${colors.slate["900"]};
  }
`;
