import { type IGenHeader } from "@/services/graphql/__generated/sdk";
import { colors } from "@/styles/styleVariables";

import Link from "next/link";
import { useRouter } from "next/router";
import React, { type FunctionComponent, useEffect, useState } from "react";

import BurgerMenu from "./burgerMenu/BurgerMenu";
import * as styles from "./Header.style";
import { headerResponsiveBreakPoint } from "./Header.style";
import Navigation from "./navigation/Navigation";
import Sidebar from "./sidebar/Sidebar";
import ContentWrapper from "../contentWrapper/ContentWrapper";

export type HeaderProps = IGenHeader & { readonly isHeaderFloating: boolean };

const Header: FunctionComponent<HeaderProps> = ({
  isHeaderFloating,
  links,
  logoDark,
  logoLight
}) =>
{
  const router = useRouter();
  const { asPath, pathname, query } = router;
  const [isSidebarActive, setIsSidebarActive] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(!isHeaderFloating);

  const onScroll = (): void => setIsScrolled((document.body.scrollTop || document.documentElement.scrollTop) > 0);

  useEffect(() =>
  {
    if(!isHeaderFloating)
    {
      setIsScrolled(true);
    }

  }, [asPath, isHeaderFloating]);

  useEffect(() => 
  {
    if(!isHeaderFloating) { return; }

    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [isHeaderFloating]);

  return (
    <>
      <div css={[styles.wrapper, isScrolled && styles.wrapperScrolled]}>
        <Sidebar
          isActive={isSidebarActive}
          navigationItems={(
            <Navigation
              isScrolled={isScrolled}
              closeSidebar={() => setIsSidebarActive(false)}
              isSidebar
              links={links}
            />
          )}
          css={styles.navLinkStyles}
        />
        <BurgerMenu
          isActive={isSidebarActive}
          onClick={() => setIsSidebarActive(!isSidebarActive)}
          responsiveBreakpointInPx={headerResponsiveBreakPoint}
          color={isScrolled ? colors.slate["900"] : colors.white}
        />
        <ContentWrapper>
          <div css={styles.headerContentWrapper}>
            <Link href="/">
              <img
                loading="eager"
                alt="Logo"
                src={logoLight?.src || ""}
                css={styles.logo(!isScrolled)}
              />
              <img
                loading="eager"
                alt="Logo"
                src={logoDark?.src || ""}
                css={styles.logo(isScrolled)}
              />
            </Link>
            <div css={styles.navAndLanguageSelect}>
              <Navigation
                isSidebar={false}
                isScrolled={isScrolled}
                links={links}
              />
              <select
                css={styles.languageSelect(isScrolled)}
                onChange={e => void router.push({ pathname, query }, asPath, { locale: e.target.value })}
                name="language select"
                value={router.locale}>
                <option value="de">DE</option>
                <option value="en">EN</option>
                <option value="fr">FR</option>
                <option value="nl">NL</option>
              </select>
            </div>
          </div>
        </ContentWrapper>
      </div>
      {!isHeaderFloating && (
        <div css={styles.placeholder}/>
      )}
    </>
  );
};

export default Header;
