import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import React, { type Dispatch, type FunctionComponent, type SetStateAction } from "react";

interface IProps
{
  readonly confirmButtonText: string;
  readonly description: string;
  readonly isAlertOpen: boolean;
  readonly setIsAlertOpen: Dispatch<SetStateAction<boolean>>;
  readonly title: string;
}

const AlertDialog: FunctionComponent<IProps> = ({
  confirmButtonText,
  description,
  isAlertOpen,
  setIsAlertOpen,
  title,
}) =>
{
  return (
    <Dialog
      open={isAlertOpen}
      onKeyUp={(e) =>
      {
        if(e.key === "Enter" || e.key === "Escape")
        {
          setIsAlertOpen(false);
        }
      }}
      disableScrollLock
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      {description !== "" && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          variant="text"
          onClick={() => setIsAlertOpen(false)}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
