import { isValidUrl } from "@/utils/validUrl";

import { Image, type ImageProps } from "@unpic/react";
import React, { type FunctionComponent } from "react";

const UnpicImage: FunctionComponent<ImageProps> = ({
  alt,
  src,
  ...props
}) =>
{
  if(typeof src !== "string" || !isValidUrl(src))
  { 
    return <p>Invalid Image URL</p>;
  } 

  return (
    <Image
      {...props}
      src={src}
      alt={alt || "missing alt text"}
      cdn="bunny"
    />
  );
};

export default UnpicImage;
