/* eslint-disable react/jsx-max-props-per-line */
import { type Nullable } from "@/utils/types";

import Head from "next/head";
import { useRouter } from "next/router";
import React, { type FunctionComponent, type ReactElement } from "react";

interface IProps
{
  readonly additionalHtmlHeadTags?: ReactElement;
  readonly faviconIco: Nullable<string>;
  readonly faviconPng: Nullable<string>;
  readonly pageDescription: Nullable<string>;
  readonly pageOgImage: Nullable<string>;
  readonly pageTitle: Nullable<string>;
  readonly themeColor: Nullable<string>;
  readonly websiteOgImage: Nullable<string>;
  readonly websiteTitle: Nullable<string>;
  readonly websiteUrl: string;
}

const getAssetDimensionsUrlParams = (sizeInPx: number): string =>
{
  return `width=${sizeInPx}&height=${sizeInPx}`;
};

const PageHead: FunctionComponent<IProps> = ({
  additionalHtmlHeadTags,
  faviconIco,
  faviconPng,
  pageDescription,
  pageOgImage,
  pageTitle,
  themeColor,
  websiteOgImage,
  websiteTitle,
  websiteUrl
}) =>
{
  if(websiteUrl.endsWith("/"))
  {
    websiteUrl = websiteUrl.slice(0, -1);
  }

  const { asPath, locale } = useRouter() || "";
  const url = websiteUrl + asPath;
  let title: string;

  if(asPath === "/")
  {
    if(pageTitle)
    {
      title = `${websiteTitle} - ${pageTitle}`;
    }
    else
    {
      title = websiteTitle || "";
    }
  }
  else
  {
    title = `${pageTitle} - ${websiteTitle}`;
  }

  const ogImageUrl = pageOgImage || websiteOgImage;

  return (
    <Head>
      <title>{title}</title>
      {/* eslint-disable-next-line @next/next/next-script-for-ga */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PRPZ7WH');
          `,
        }}
      />
      <meta charSet="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=10.0"/>
      <meta name="robots" content="index, follow"/>
      {themeColor && (
        <>
          <meta name="theme-color" content={themeColor}/>
          <meta name="msapplication-TileColor" content={themeColor}/>
        </>
      )}
      {faviconPng && (
        <>
          <link rel="apple-touch-icon" sizes="180x180" href={`${faviconPng}?${getAssetDimensionsUrlParams(180)}`}/>
          <link rel="icon" type="image/png" sizes="32x32" href={`${faviconPng}?${getAssetDimensionsUrlParams(32)}`}/>
          <link rel="icon" type="image/png" sizes="16x16" href={`${faviconPng}?${getAssetDimensionsUrlParams(16)}`}/>
        </>
      )}
      {faviconIco && <link rel="shortcut icon" href={faviconIco}/>}
      {pageDescription && (
        <>
          <meta name="description" content={pageDescription}/>
          <meta property="og:description" content={pageDescription}/>
          <meta itemProp="description" content={pageDescription}/>
          <meta name="twitter:description" content={pageDescription}/>
        </>
      )}
      {websiteTitle && (
        <>
          <meta name="application-name" content={websiteTitle}/>
          <meta property="og:site_name" content={websiteTitle}/>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebSite",
                name: websiteTitle,
                url: websiteUrl
              })
            }}
          />
        </>
      )}
      <meta property="og:title" content={title}/>
      {ogImageUrl && (
        <>
          <meta property="og:image" content={ogImageUrl}/>
          <meta itemProp="image" content={ogImageUrl}/>
          <meta name="twitter:image" content={ogImageUrl}/>
          <meta name="twitter:card" content="summary_large_image"/>
        </>
      )}
      <meta property="og:url" content={url}/>
      <meta property="og:type" content="website"/>
      <meta property="og:locale" content={locale}/>
      <meta itemProp="name" content={title}/>
      <meta name="twitter:title" content={title}/>
      {additionalHtmlHeadTags}
    </Head>
  );
};

export default PageHead;
